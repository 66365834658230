import http from "../../config/http-common";

class RoleService {
  getRole = (roleId) => {
    return new Promise((resolve, reject) => {
      http.get("Role/GetRole/" + roleId).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  getRoles = (
    phoneNumber = 1,
    pageSize = 10,
    sortColumn = "CreationDateTime",
    sortOrder = "desc",
    searchTerm = ""
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "Role/GetRoles/" +
            phoneNumber +
            "/" +
            pageSize +
            "/" +
            sortColumn +
            "/" +
            sortOrder +
            "/" +
            searchTerm
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateRole = (role) => {
    return new Promise((resolve, reject) => {
      http.post("Role/AddUpdateRole", role).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  deleteRole = (roleId) => {
    return new Promise((resolve, reject) => {
      http.post("Role/RemoveRole/" + roleId).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };
}
const instance = new RoleService();

export default instance;
