import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../src/app/components/private-route";
import SignIn from "./app/auth/signin/sign-in";
import SignInAdmin from "./app/auth/admin/signin/sign-in";
import ForgotPassword from "./app/auth/forgot-password/forgot-password";
import ForgotAdminPassword from "./app/auth/admin/signin/forgot-password";
import Home from "./app/main/dashboard/home";
import AdminHome from "./app/main/admin/dashboard/home";
import Setting from "./app/main/settings/setting";
import AdminSetting from "./app/main/admin/settings/setting";
import Roles from "./app/main/admin/roles/roles";
import Detail from "./app/main/admin/user-details/detail";
import AdminDetail from "./app/main/admin/admin-details/detail";
import { theme } from "./app/config/style.config";
import { ThemeProvider } from "@mui/material/styles";
import TermsConditions from "./app/auth/terms/termsConditions";
import Invitation from "./app/auth/admin/signin/invitation";
import Users from "./app/main/admin/users/users";
import AdminUsers from "./app/main/admin/admin-users/users";
import AuthService from "./app/auth/services/authService";
import constants from "../src/app/helpers/constants";
import ErrorPage from "./app/404";

function App() {
  const userDetail = AuthService.getUser();
  const roles = constants?.roles();
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/admin/" element={<SignInAdmin />} />
          <Route path="/admin/sign-in" element={<SignInAdmin />} />
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/invitation/:id" element={<Invitation />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/admin/forgot-password"
            element={<ForgotAdminPassword />}
          />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/404" element={<ErrorPage />} />
          {userDetail?.users?.roleId === roles.admin ||
          userDetail?.users?.roleId === roles.superAdmin ? (
            <Route element={<PrivateRoute />}>
              <Route path="/admin/home" element={<AdminHome />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/admin-users" element={<AdminUsers />} />
              <Route path="/admin/roles" element={<Roles />} />
              <Route path="/admin/setting" element={<AdminSetting />} />
              <Route path="/admin/user-detail/:id" element={<Detail />} />
              <Route path="/admin/admin-detail/:id" element={<AdminDetail />} />
            </Route>
          ) : (
            <Route element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/setting" element={<Setting />} />
            </Route>
          )}
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
