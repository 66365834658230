import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AuthService from "../../auth/services/authService";
import UserService from "../services/userService";
import FadeLoader from "react-spinners/FadeLoader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dateFormat, { masks } from "dateformat";
import Tooltip from "@mui/material/Tooltip";

function Home() {
  const userDetail = AuthService.getUser();
  const [taxPayerList, setTaxPayerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taxYear, setTaxYear] = useState(2023);
  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  function onGetTaxPayers(taxYear) {
    setLoading(true);
    UserService.getTaxpayers(taxYear, userDetail?.users?.userId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setTaxPayerList(response.data);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  useEffect(() => {
    onGetTaxPayers(taxYear);
  }, []);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }
  return (
    <div className="h-[calc(100vh_-_80px)] overflow-hidden">
      <div className="h-full p-6 flex flex-col">
        <h2 className="font-medium text-primaryLime-500">
          Tax Return Files by Tax Year
        </h2>
        <div className="relative flex alig-items mt-2 mb-4">
          <label className="flex items-center mr-4">Tax Year</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="w-32"
            value={taxYear}
            onChange={(event) => {
              setTaxYear(event?.target?.value);
              onGetTaxPayers(event?.target?.value);
            }}
          >
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2022">2022</MenuItem>
            <MenuItem value="2021">2021</MenuItem>
          </Select>
        </div>

        <div className="flex items-center justify-center flex-col">
          {loading && (
            <div className="flex items-center justify-center w-full mb-4">
              <FadeLoader
                color="#000000"
                loading={loading}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="mx-auto w-8"
              />
            </div>
          )}
          {(taxPayerList.length > 0 && (
            <div className="w-full home-cards gap-6 flex flex-wrap overflow-auto h-[calc(100vh_-_245px)]">
              {taxPayerList.map((tax) => (
                <Card>
                  <CardContent>
                    <div className="flex w-full mb-3">
                      <div className="w-1/2">
                        <Typography variant="h5" component="div">
                          TaxPayer
                        </Typography>
                        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                          {tax?.taxpayer?.firstName +
                            " " +
                            tax?.taxpayer?.lastName}
                        </Typography>
                      </div>
                      <div className="w-1/2">
                        <Typography variant="h5" component="div">
                          SSN
                        </Typography>
                        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                          {tax?.taxpayer?.ssn}
                        </Typography>
                      </div>
                    </div>
                    {tax?.taxReturns.length > 0 && (
                      <div className="w-full mb-2">
                        <h3>Tax Returns</h3>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 400 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Date</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tax?.taxReturns.map((row) => (
                                <TableRow
                                  key={row.taxreturnID}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row?.returnType &&
                                    row?.returnType !== "Fed"
                                      ? row?.returnType + " State"
                                      : row?.returnType
                                      ? row?.returnType
                                      : "Fed"}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row.returnStatus === "R"
                                      ? "Rejected"
                                      : row.returnStatus === "A"
                                      ? "Accepted"
                                      : row.returnStatus === "SNTF"
                                      ? "Draft"
                                      : ""}
                                    <br />
                                    {row?.returnStatus === "R" ? (
                                      <small>
                                        Rejection Code: {row?.rejectionCode}{" "}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row?.efileDate
                                      ? dateFormat(row?.efileDate, "dd/mm/yyyy")
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
                    {tax?.taxReturnFiles.length > 0 && (
                      <div className="mt-2">
                        <h3>Document</h3>
                        <div className="w-full flex gap-5">
                          {tax?.taxReturnFiles.map((file) => (
                            <div className="flex w-full lg:w-1/3 border rounded-12 p-3 items-center justify-between mt-2">
                              <div className="flex items-center">
                                <div className="mr-2">
                                  <i class="fi fi-rr-file-pdf text-2xl flex"></i>
                                </div>
                                <div>{file?.logicalName}</div>
                              </div>
                              <div className="flex items-center">
                                <a class="text-primaryLime-500 text-lg mr-2">
                                  <Tooltip title="View">
                                    <div>
                                      <i class="fi fi-rr-eye flex"></i>
                                    </div>
                                  </Tooltip>
                                </a>{" "}
                                <a class="text-primaryLime-500 text-lg">
                                <Tooltip title="Download">
                                    <div>
                                  <i class="fi fi-rr-cloud-download-alt flex"></i>
                                  </div>
                                  </Tooltip>
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* {tax?.returnStatus === "R" && (
                      <>
                        <Typography variant="h5" component="div">
                          Rejection Code
                        </Typography>
                        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                          {tax?.rejectionCode}
                        </Typography>
                      </>
                    )}
                    <div className="w-1/2">
                        <Typography variant="h5" component="div">
                        Return Status
                        </Typography>
                        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                          <p className="ml-3 hidden rounded-lg bg-slate-100 px-2 py-0.5 text-xs/6 font-semibold whitespace-nowrap text-slate-700 lg:block"></p>
                          {tax?.status}
                        </Typography>
                      </div>
                    <div>
                      {tax?.returnStatus === "A" && (
                        <div className="flex items-center">
                          <Typography
                            className="min-w-32"
                            variant="h5"
                            component="div"
                          >
                            Tax Return
                          </Typography>
                          <Typography sx={{ color: "text.secondary" }}>
                            <a class="text-primaryLime-500 underline text-base">
                              View
                            </a>{" "}
                            |{" "}
                            <a class="text-primaryLime-500 underline text-base">
                              Download
                            </a>
                          </Typography>
                        </div>
                      )}
                      {tax?.extension === true && (
                        <div className="flex items-center">
                          <Typography
                            className="min-w-32"
                            variant="h5"
                            component="div"
                          >
                            Tax Extension
                          </Typography>
                          <Typography sx={{ color: "text.secondary" }}>
                            <a class="text-primaryLime-500 underline text-base">
                              View
                            </a>{" "}
                            |{" "}
                            <a class="text-primaryLime-500 underline text-base">
                              Download
                            </a>
                          </Typography>
                        </div>
                      )}
                    </div> */}
                  </CardContent>
                </Card>
              ))}
            </div>
          )) ||
            (taxPayerList.length === 0 && !loading && (
              <div className="flex items-center justify-center text-center w-full">
                No data found
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
export default Home;
