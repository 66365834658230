import { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import constants from "../../../helpers/constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import _ from "../../../../../src/@lodash";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import successfullyMark from "../../../assets/images/success-mark.png";
import InvitationService from "../../services/invitationService";
import UserService from "../../services/userService";
import AuthService from "../../../auth/services/authService";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as yup from "yup";
import dateFormat, { masks } from "dateformat";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

var schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|in)$/,
      "Invalid email"
    )
    .required("Email is required"),
  roleID: yup
    .string()
    .required("Role is required")
    .notOneOf(["-1"], "Please select a valid option"),
});

var defaultValues = {
  email: "",
  roleID: "",
};

function InvitationList() {
  const navigate = useNavigate();
  const emailFieldRef = useRef(null);
  const userDetail = AuthService.getUser();
  const [permission, setPermission] = useState(
    userDetail.roles.permissionIds
      ? userDetail.roles.permissionIds.split(",").map(Number)
      : []
  );
  const settingSteps = constants?.signUpSteps();
  const [emailSteps, setEmailSteps] = useState(settingSteps.signUp);
  const [email, setEmail] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const roles = constants?.roles();
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      disableColumnMenu: true,
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: "roleName",
      headerName: "Role Name",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "invitationSentDateTime",
      headerName: "DateTime",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "invitationCode",
      headerName: "Code",
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <a
            class="text-primaryLime-500 underline text-base"
            onClick={() => {
              handleCopyClick(
                process.env.REACT_APP_BASEURL + "invitation/" + params.value
              );
            }}
            rel="noopener noreferrer"
          >
            Copy Invitation URL
          </a>
        );
      },
    },
  ];

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
    vertical: "top",
    horizontal: "center",
  });

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const { appOpen, appStatus, appMessage, vertical, horizontal } = state;

  const setInvitation = () => {
    setValue("email", "", {
      shouldDirty: true,
      shouldValidate: false,
    });
    setValue("roleID", "-1", {
      shouldDirty: true,
      shouldValidate: false,
    });
  };

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const fetchDataWithSortingAndPagination = async (
    sortField,
    sortDirection
  ) => {
    setLoading(true);
    InvitationService.getInvitations(
      page + 1,
      pageSize,
      sortField,
      sortDirection
    )
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            response.data.map((data) => {
              if (data.invitationSentDateTime)
                data.invitationSentDateTime = dateFormat(
                  data.invitationSentDateTime,
                  "dd/mm/ yyyy h:MM:ss TT"
                );
            });
            setRows(response.data);
            setRowCount(response.rowCount);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  };

  function getRoles() {
    UserService.getRoles()
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            response.data = response.data.filter((data) => {
              return (
                data.name !== constants.user &&
                data.name !== constants.superAdmin
              );
            });
            setRoleList(response.data);
          }
        }
      })
      .catch((_errors) => {
        showAppMessage(_errors);
      });
  }

  useEffect(() => {
    if (
      !permission.includes(constants.invitationView) &&
      userDetail?.users?.roleId !== roles.superAdmin
    ) {
      navigate("/404");
    } else {
      fetchDataWithSortingAndPagination("InvitationSentDateTime", "desc");
      getRoles();
    }
  }, [page, pageSize]);

  function onSubmit({ email, roleID }) {
    setLoading(true);
    setEmail(email);
    InvitationService.sendMemberInvitation({
      emailAddress: email,
      roleId: roleID,
    })
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setEmailSteps(settingSteps.signUpSuccess);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div>
      {permission.includes(constants.invitationCreate) ||
      userDetail?.users?.roleId === roles.superAdmin ? (
        <form name="settingForm" noValidate className="w-full">
          <div className="flex items-center justify-end">
            <p className="leading-7 text-right mb-4">
              <Link
                className="text-primaryLime-500 underline text-base"
                onClick={() => {
                  setEmailSteps(settingSteps.signUp);
                  setInvitation();
                  setShowModal(true);
                  setTimeout(() => {
                    emailFieldRef.current?.focus();
                  }, 5);
                }}
              >
                +Send Invitation
              </Link>
            </p>
          </div>
        </form>
      ) : (
        <></>
      )}
      <div className="h-[calc(100vh_-_225px)] md:h-[calc(100vh_-_292px)]">
        <DataGrid
          rows={rows}
          columns={rows.length > 0 ? columns : []}
          pageSize={pageSize}
          rowCount={rowCount}
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          columnVisibilityModel={{ id: false }}
          sortingOrder={["asc", "desc"]}
          onSortModelChange={(newSortModel) => {
            const sortField = newSortModel[0]?.field;
            const sortDirection = newSortModel[0]?.sort;
            fetchDataWithSortingAndPagination(sortField, sortDirection);
          }}
          onPaginationModelChange={(mode) => {
            setPage(mode.page);
            setPageSize(mode.pageSize);
            fetchDataWithSortingAndPagination("InvitationSentDateTime", "desc");
          }}
          loading={loading}
          paginationMode="server"
          pagination
        />
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {(emailSteps === settingSteps.signUp && (
            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full relative"
              onSubmit={handleSubmit(onSubmit)}
            >
              <a
                className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                onClick={() => setShowModal(false)}
              >
                <i className="fi fi-rr-circle-xmark"></i>{" "}
              </a>
              <DialogTitle
                id="alert-dialog-title"
                class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
              >
                Send Invitation
              </DialogTitle>
              <DialogContent className="dialog-content">
                <DialogContentText id="alert-dialog-description">
                  <div>
                    <p className="text-center text-black">
                      Enter the email address below and select the role.
                    </p>
                    <div className="mt-[50px] mb-8">
                      <div className="relative">
                        <label className="mb-2">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="w-full p-4"
                              autoFocus={true}
                              type="email"
                              error={!!errors.email}
                              helperText={errors?.email?.message}
                              variant="outlined"
                              placeholder="Enter Email address"
                              inputProps={{ maxLength: 40 }}
                              required
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="relative select-menu">
                      <label className="block">Role</label>
                      <Controller
                        className="w-full role-menu"
                        name="roleID"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            error={!!errors.roleID}
                            helperText={errors?.roleID?.message}
                          >
                            <MenuItem value="-1" selected>
                              Select
                            </MenuItem>
                            {roleList.map((option) => (
                              <MenuItem value={option.roleId}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <div className="absolute tp-message">
                        <AppMessage
                          status={appStatus}
                          message={appMessage}
                          vertical="top"
                          horizontal="center"
                          isOpen={appOpen}
                          timeOut={100}
                        ></AppMessage>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-actions">
                <div className="w-full">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                    aria-label="Sign in"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    type="submit"
                    size="large"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {(loading && (
                      <PulseLoader
                        color="#000000"
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )) ||
                      "Save"}
                  </Button>
                </div>
              </DialogActions>
            </form>
          )) ||
            (emailSteps === settingSteps.signUpSuccess && (
              <div>
                {/* <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => {setShowModal(false); fetchDataWithSortingAndPagination("InvitationSentDateTime", "desc");}}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a> */}
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                >
                  <span>
                    <img
                      className="mx-auto block mb-4 w-[50px]"
                      src={successfullyMark}
                      alt="successfully"
                    />
                  </span>
                  Invitation Sent
                </DialogTitle>

                <DialogContent className="dialog-content h-full overflow-visible">
                  <DialogContentText id="alert-dialog-description">
                    <div className="text-center text-black h-full">
                      Your invitation has been successfully sent on{" "}
                      <span className="font-semibold">{email}</span>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        setShowModal(false);
                        fetchDataWithSortingAndPagination(
                          "InvitationSentDateTime",
                          "desc"
                        );
                      }}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))}
        </Dialog>
      </div>
      {copied && (
        <Snackbar open={copied} anchorOrigin={{ vertical, horizontal }}>
          <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
            Copied!
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
export default InvitationList;
