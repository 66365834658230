import React, { useState } from "react";
import { Link } from "react-router-dom";
import constants from "../helpers/constants";
import AuthService from "../auth/services/authService";
import logo from "../assets/images/efile-logo.png";

function Sidebar({ handleOnInAciveClick }) {
  const sidebarMenu = constants?.sidebarMenu();
  const roles = constants?.roles();
  const userDetail = AuthService.getUser();
  const [activeSidebarMenu, setActiveSidebarMenu] = useState(
    window.location.pathname === "/setting" ||
      window.location.pathname === "/admin/setting"
      ? sidebarMenu.settings
      : window.location.pathname === "/admin/users" ||
        window.location.pathname.includes("/admin/user-detail")
      ? sidebarMenu.users
      : window.location.pathname === "/admin/roles"
      ? sidebarMenu.roles
      : window.location.pathname === "/admin/admin-users" ||
        window.location.pathname.includes("/admin/admin-detail")
      ? sidebarMenu.adminUsers
      : window.location.pathname === "/admin/home"
      ? sidebarMenu.adminDashboard
      : sidebarMenu.dashboard
  );

  return (
    <div className="min-w-[250px] bg-black h-full">
      <div className="h-[60px] lg:h-[80px] flex items-center justify-between lg:justify-center border-b border-gray-800 px-4 lg:px-0">
        <img className="h-full p-5" src={logo} alt="auth image" />
        <a className="block lg:hidden" onClick={() => handleOnInAciveClick()}>
          <i class="fi fi-rr-cross text-white"></i>
        </a>
      </div>
      <div className="relative taxpert-sidebar">
        <ul className="text-white list-none">
          {userDetail?.users?.roleId === roles.superAdmin ||
          userDetail?.users?.roleId === roles.admin ? (
            <>
              <li>
                <Link
                  to="/admin/home"
                  onClick={() => {
                    handleOnInAciveClick();
                    setActiveSidebarMenu(sidebarMenu.adminDashboard);
                  }}
                  className={
                    activeSidebarMenu === sidebarMenu.adminDashboard
                      ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                      : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
                  }
                >
                  <i class="fi fi-rr-home mr-3 top-[2px] relative"></i>Home
                </Link>
              </li>
              <li>
                <Link
                  to={"/admin/setting"}
                  onClick={() => {
                    handleOnInAciveClick();
                    setActiveSidebarMenu(sidebarMenu.settings);
                  }}
                  className={
                    activeSidebarMenu === sidebarMenu.settings
                      ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                      : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
                  }
                >
                  <i class="fi fi-rr-settings mr-3 top-[2px] relative"></i>
                  Settings
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/users"
                  onClick={() => {
                    handleOnInAciveClick();
                    setActiveSidebarMenu(sidebarMenu.users);
                  }}
                  className={
                    activeSidebarMenu === sidebarMenu.users
                      ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                      : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
                  }
                >
                  <i class="fi fi-rr-users-alt mr-3 top-[2px] relative"></i>
                  Users
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/admin-users"
                  onClick={() => {
                    handleOnInAciveClick();
                    setActiveSidebarMenu(sidebarMenu.adminUsers);
                  }}
                  className={
                    activeSidebarMenu === sidebarMenu.adminUsers
                      ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                      : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
                  }
                >
                  <i class="fi fi-rr-users-alt mr-3 top-[2px] relative"></i>
                  Admin Users
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/roles"
                  onClick={() => {
                    handleOnInAciveClick();
                    setActiveSidebarMenu(sidebarMenu.roles);
                  }}
                  className={
                    activeSidebarMenu === sidebarMenu.roles
                      ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                      : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
                  }
                >
                  <i class="fi fi-rr-users-gear mr-3 top-[2px] relative"></i>
                  Roles
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to="/home"
                  onClick={() => {
                    handleOnInAciveClick();
                    setActiveSidebarMenu(sidebarMenu.dashboard);
                  }}
                  className={
                    activeSidebarMenu === sidebarMenu.dashboard
                      ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                      : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
                  }
                >
                  <i class="fi fi-rr-home mr-3 top-[2px] relative"></i>Home
                </Link>
              </li>
              <li>
                <Link
                  to={"/setting"}
                  onClick={() => {
                    handleOnInAciveClick();
                    setActiveSidebarMenu(sidebarMenu.settings);
                  }}
                  className={
                    activeSidebarMenu === sidebarMenu.settings
                      ? "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item active"
                      : "hover:bg-gray-900 no-underline text-base font-medium p-4 w-full flex items-center transition duration-300 ease-in-out sidebar-list-item"
                  }
                >
                  <i class="fi fi-rr-settings mr-3 top-[2px] relative"></i>
                  Settings
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
export default Sidebar;
