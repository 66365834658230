import { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import constants from "../../../helpers/constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import _ from "../../../../../src/@lodash";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import successfullyMark from "../../../assets/images/success-mark.png";
import RoleService from "../../services/roleService";
import AuthService from "../../../auth/services/authService";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as yup from "yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";

var schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z]*$/g, "Please enter between a - z, A - Z")
    .required("Role name is required"),
  status: yup
    .string()
    .required("Role is required")
    .notOneOf(["-1"], "Please select a valid option"),
});

var defaultValues = {
  name: "",
  status: "-1",
};

function Roles() {
  const navigate = useNavigate();
  const roleFieldRef = useRef(null);
  const settingSteps = constants?.signUpSteps();
  const roles = constants?.roles();
  const [userPermissions, setUserPermissions] = useState(
    constants?.userPermissions()
  );
  var userDetail = AuthService.getUser();
  const [permission, setPermission] = useState(
    userDetail.roles.permissionIds
      ? userDetail.roles.permissionIds.split(",").map(Number)
      : []
  );
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailSteps, setEmailSteps] = useState(settingSteps.signUp);
  const [roleName, setRoleName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [permissions, setPermissions] = useState([]);
  var columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Role Name",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <>{params.value == 1 ? "Active" : "In Active"}</>;
      },
    },
    {
      field: "roleId",
      headerName: "Action",
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        const roleId = params.value;
        return (
          <>
            {permission.includes(constants.roleEdit) ||
            userDetail?.users?.roleId === roles.superAdmin ? (
              <a
                class="text-primaryLime-500 underline text-base"
                onClick={() => {
                  setRoleId(roleId);
                  onGetRole(roleId);
                }}
              >
                Edit
              </a>
            ) : (
              <></>
            )}

            {(permission.includes(constants.roleEdit) &&
              permission.includes(constants.roleDelete)) ||
            userDetail?.users?.roleId === roles.superAdmin ? (
              <> | </>
            ) : (
              <></>
            )}

            {permission.includes(constants.roleDelete) ||
            userDetail?.users?.roleId === roles.superAdmin ? (
              <a
                class="text-primaryLime-500 underline text-base"
                onClick={() => {
                  setRoleId(roleId);
                  setShowDeleteModal(true);
                }}
              >
                Delete
              </a>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
    vertical: "top",
    horizontal: "center",
  });

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const { appOpen, appStatus, appMessage, vertical, horizontal } = state;

  const setRoleField = () => {
    setValue("name", "", {
      shouldDirty: true,
      shouldValidate: false,
    });
    setValue("status", "-1", {
      shouldDirty: true,
      shouldValidate: false,
    });
  };

  const fetchDataWithSortingAndPagination = async (
    sortField,
    sortDirection
  ) => {
    setLoading(true);
    RoleService.getRoles(page + 1, pageSize, sortField, sortDirection)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setRows(response.data);
            setRowCount(response.rowCount);
          } else {
            columns = [];
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  };

  function onGetRole(roleId) {
    RoleService.getRole(roleId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            if (response.data.permissionIds) {
              response.data.permissionIds = response.data.permissionIds
                .split(",")
                .map(Number);
            }
            setEmailSteps(settingSteps.signUp);
            setValue("name", response.data.name, {
              shouldDirty: true,
              shouldValidate: false,
            });
            setValue("status", response.data.isActive ? "1" : "0", {
              shouldDirty: true,
              shouldValidate: false,
            });
            setPermissions(response.data.permissionIds || []);
            setShowModal(true);
            setTimeout(() => {
              roleFieldRef.current?.focus();
            }, 5);
          }
        }
      })
      .catch((_errors) => {
        showAppMessage(_errors);
      });
  }

  function onDeleteRole() {
    RoleService.deleteRole(roleId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            fetchDataWithSortingAndPagination("Name", "desc");
            setShowDeleteModal(false);
          }
        }
      })
      .catch((_errors) => {
        showAppMessage(_errors);
      });
  }

  function onSubmit({ name, status }) {
    setLoading(true);
    setRoleName(name);
    if (permissions.length > 0) setPermissions(_.uniq(permissions));
    var roleObj = {
      roleID: roleId || 0,
      name: name,
      isActive: status === "1" ? true : false,
      permissionIds: permissions.length > 0 ? permissions.toString() : "",
    };
    RoleService.addUpdateRole(roleObj)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            if (
              userDetail?.roles?.roleID === roleId ||
              userDetail?.roles?.roleId === roleId
            ) {
              userDetail.roles = roleObj;
              AuthService.setUser(userDetail);
            }
            setEmailSteps(settingSteps.signUpSuccess);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function handleChange(isChecked, value) {
    var permissionData = permissions;
    if (isChecked) {
      permissionData.push(value);
    } else {
      if (permissionData.length > 0) {
        permissionData = permissionData.filter((item) => item !== value);
      }
    }
    setPermissions(permissionData);
    setUserPermissions(constants?.userPermissions());
  }

  useEffect(() => {
    if (
      !permission.includes(constants.roleView) &&
      userDetail?.users?.roleId !== roles.superAdmin
    ) {
      navigate("/404");
    } else {
      fetchDataWithSortingAndPagination("Name", "desc");
    }
  }, [page, pageSize]);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="">
      <div className="h-full p-6 md:p-8 lg:p-12 xl:py-[50px] xl:px-[50px] flex flex-col">
        {permission.includes(constants.roleCreate) ||
        userDetail?.users?.roleId === roles.superAdmin ? (
          <form name="settingForm" noValidate className="w-full">
            <div className="flex items-center justify-end">
              <p className="leading-7 text-right mb-4">
                <Link
                  className="text-primaryLime-500 underline text-base"
                  onClick={() => {
                    setRoleField();
                    setRoleId("");
                    setEmailSteps(settingSteps.signUp);
                    setShowModal(true);
                    setTimeout(() => {
                      roleFieldRef.current?.focus();
                    }, 5);
                  }}
                >
                  +Add Role
                </Link>
              </p>
            </div>
          </form>
        ) : (
          <></>
        )}
        <div className="h-[calc(100vh_-_163px)] md:h-[calc(100vh_-_230px)] data-table">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            rowsPerPageOptions={[5, 10, 25]}
            page={page}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            columnVisibilityModel={{
              id: false,
              userId:
                permission.includes(constants.roleEdit) ||
                permission.includes(constants.roleDelete) ||
                userDetail?.users?.roleId === roles.superAdmin,
            }}
            sortingOrder={["asc", "desc"]}
            onSortModelChange={(newSortModel) => {
              const sortField = newSortModel[0]?.field;
              const sortDirection = newSortModel[0]?.sort;
              fetchDataWithSortingAndPagination(sortField, sortDirection);
            }}
            onPaginationModelChange={(mode) => {
              setPage(mode.page);
              setPageSize(mode.pageSize);
              fetchDataWithSortingAndPagination("Name", "desc");
            }}
            loading={loading}
            paginationMode="server"
            pagination
          />
        </div>
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {(emailSteps === settingSteps.signUp && (
            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full relative"
              onSubmit={handleSubmit(onSubmit)}
            >
              <a
                className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                onClick={() => setShowModal(false)}
              >
                <i className="fi fi-rr-circle-xmark"></i>{" "}
              </a>
              <DialogTitle
                id="alert-dialog-title"
                class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
              >
                {roleId ? "Edit " : "Add "}Role
              </DialogTitle>
              <DialogContent className="dialog-content">
                <DialogContentText id="alert-dialog-description">
                  <div>
                    <p className="text-center text-black">
                      Enter the role name, select status, and define the
                      permissions below.
                    </p>
                    <div className="mt-[50px] mb-8">
                      <div className="relative">
                        <label className="mb-2">Role Name</label>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="w-full p-4"
                              autoFocus={true}
                              type="text"
                              inputRef={roleFieldRef}
                              error={!!errors.name}
                              helperText={errors?.name?.message}
                              variant="outlined"
                              placeholder="Enter Role"
                              inputProps={{ maxLength: 20 }}
                              required
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="relative select-menu mb-8">
                      <label className="block">Status</label>
                      <Controller
                        className="w-full role-menu"
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            error={!!errors.status}
                            helperText={errors?.status?.message}
                          >
                            <MenuItem value="-1" selected>
                              Select
                            </MenuItem>
                            <MenuItem value="0">In Active</MenuItem>
                            <MenuItem value="1">Active</MenuItem>
                          </Select>
                        )}
                      />
                      <div className="absolute tp-message">
                        <AppMessage
                          status={appStatus}
                          message={appMessage}
                          vertical="top"
                          horizontal="center"
                          isOpen={appOpen}
                          timeOut={100}
                        ></AppMessage>
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block">Permission</label>
                      <div className="data-table">
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Page</TableCell>
                                <TableCell align="right">View</TableCell>
                                <TableCell align="right">Create</TableCell>
                                <TableCell align="right">Edit</TableCell>
                                <TableCell align="right">Delete</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userPermissions.map((row) => (
                                <TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Switch
                                      onChange={(event) => {
                                        handleChange(
                                          event.target.checked,
                                          row.view
                                        );
                                      }}
                                      checked={permissions.includes(row.view)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <Switch
                                      onChange={(event) => {
                                        handleChange(
                                          event.target.checked,
                                          row.create
                                        );
                                      }}
                                      checked={permissions.includes(row.create)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <Switch
                                      disabled={row.name === "Invitation"}
                                      onChange={(event) => {
                                        handleChange(
                                          event.target.checked,
                                          row.edit
                                        );
                                      }}
                                      checked={permissions.includes(row.edit)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <Switch
                                      disabled={row.name === "Invitation"}
                                      onChange={(event) => {
                                        handleChange(
                                          event.target.checked,
                                          row.delete
                                        );
                                      }}
                                      checked={permissions.includes(row.delete)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-actions">
                <div className="w-full">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                    aria-label="Sign in"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    type="submit"
                    size="large"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {(loading && (
                      <PulseLoader
                        color="#000000"
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )) ||
                      "Save"}
                  </Button>
                </div>
              </DialogActions>
            </form>
          )) ||
            (emailSteps === settingSteps.signUpSuccess && (
              <div>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                >
                  <span>
                    <img
                      className="mx-auto block mb-4 w-[50px]"
                      src={successfullyMark}
                      alt="successfully"
                    />
                  </span>
                  Role Set!
                </DialogTitle>

                <DialogContent className="dialog-content h-full overflow-visible">
                  <DialogContentText id="alert-dialog-description">
                    <div className="text-center text-black h-full">
                      The role has been successfully{" "}
                      {roleId ? "updated" : "added"}.{" "}
                      <span className="font-semibold">{roleName}</span>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        setShowModal(false);
                        fetchDataWithSortingAndPagination("Name", "desc");
                      }}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))}
        </Dialog>
      </div>
      <div>
        <Dialog
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle
              id="alert-dialog-title"
              class="font-familjen px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 text-[32px]"
            ></DialogTitle> */}
          <DialogContent className="dialog-content small">
            <DialogContentText id="alert-dialog-description">
              <div className="text-center">
                <div className="flex items-center justify-center bg-red-100 w-14 h-14 rounded-12 mx-auto mt-6 mb-3">
                  <i class="fi fi-rr-triangle-warning text-red-500 text-2xl flex items-center"></i>
                </div>
                <div>
                  <div className="text-[32px] font-semibold text-black">
                    Delete Role
                  </div>
                  <div className="text-black text-muted">
                    {" "}
                    Are you sure you want to delete this role?
                    <span className="block">
                      This action cannot be undone and may affect users assigned
                      to this role.
                    </span>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-actions small">
            <div className="w-full flex items-center justify-center">
              <Button
                variant="contained"
                color="secondary"
                className="border-0 bg-red-500 text-white rounded-xl transition duration-300 ease-in-out shadow-none delete-btn btn-small"
                aria-label="Sign in"
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  onDeleteRole();
                }}
              >
                {(loading && (
                  <PulseLoader
                    color="#000000"
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )) ||
                  "Delete"}
              </Button>
              <Link
                variant="contained"
                color="secondary"
                className="underline ml-6"
                aria-label="Sign in"
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Cancel
              </Link>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default Roles;
