import { useEffect, useState, useRef } from "react";
import constants from "../../../helpers/constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import _ from "../../../../../src/@lodash";
import AuthService from "../../../auth/services/authService";
import UserService from "../../services/userService";
import AdminService from "../../services/adminService";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import OtpInput from "react-otp-input";
import successfullyMark from "../../../assets/images/success-mark.png";
import Emitter from "../../services/eventEmitter";
import * as yup from "yup";

var diplaySchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|in)$/,
      "Invalid email"
    )
    .required("Email is required"),
});

var defaultDisplayValues = {
  email: "",
};

var emailSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|in)$/,
      "Invalid email"
    )
    .required("Email is required"),
});

var defaultEmailValues = {
  emailAddress: "",
};

function SettingEmail() {
  var userDetail = AuthService.getUser();
  const settingSteps = constants?.signUpSteps();
  const [emailSteps, setEmailSteps] = useState(settingSteps.signUp);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [showSecondaryEmailLink, setShowSecondaryEmailLink] = useState(
    !userDetail?.recoveryEmailAddresses?.emailAddress
  );
  const [resendOTPSeconds, setResendOTPSeconds] = useState(
    constants.ResendOTPSeconds
  );
  const [showOtpMessage, setShowOtpMessage] = useState(false);
  const emailFieldRef = useRef(null);

  var schema = showModal ? emailSchema : diplaySchema;
  var defaultValues = showModal ? defaultEmailValues : defaultDisplayValues;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const getProfile = () => {
    setValue("email", userDetail?.emailAddresses?.emailAddress || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
  };

  const setProfile = () => {
    setValue("emailAddress", userDetail?.emailAddresses?.emailAddress || "", {
      shouldDirty: true,
      shouldValidate: false,
    });
  };

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    getProfile();
    Emitter.on("update_recovery_email", (value) => {
      if (value) {
        userDetail = AuthService.getUser();
        setShowSecondaryEmailLink(
          !userDetail?.recoveryEmailAddresses?.emailAddress
        );
        getProfile();
      }
    });

    return () => {
      Emitter.off("update_recovery_email");
    };
  }, [setValue]);

  function onSubmit({ emailAddress }) {
    userDetail = AuthService.getUser();
    setLoading(true);
    setEmail(emailAddress);
    AdminService.addUpdateAdminEmailAddress(
      emailAddress,
      userDetail?.emailAddresses?.emailAddressId,
      false
    )
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            showResendOTPSeconds();
            setOtp("");
            setEmailSteps(settingSteps.signUpWithOTP);
            showHideOTPMessage();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onOtpSubmit() {
    setLoading(true);
    AdminService.verifyEmailOtp(
      email,
      userDetail?.emailAddresses?.emailAddressId,
      otp
    )
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            userDetail.emailAddresses = response.data;
            AuthService.setUser(userDetail);
            setEmailSteps(settingSteps.signUpSuccess);
            getProfile();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onReSendOTP() {
    setLoading(true);
    setEmail(email);
    AdminService.reSendEmailOTP(email)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
            showHideOTPMessage();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  function showHideOTPMessage(response) {
    setShowOtpMessage(true);
    setTimeout(() => {
      setShowOtpMessage(false);
    }, 3000);
  }

  function showResendOTPSeconds() {
    var intervalId = setInterval(() => {
      setResendOTPSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          return "00";
        }
        return prev < 11 ? `0${prev - 1}` : prev - 1;
      });
    }, constants.ResendOTPCount);
  }

  return (
    <div>
      <div>
        <form
          name="settingForm"
          noValidate
          className="flex flex-col justify-center w-full"
        >
          <div>
            <label className="mb-2">Email Address</label>
            <div className="relative">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full p-4"
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    placeholder="Enter Email address"
                    required
                    fullWidth
                    disabled
                    inputProps={{ maxLength: 40 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Link
                            className="text-primaryLime-500 leading-7 text-right underline mr-2.5"
                            onClick={() => {
                              setProfile();
                              setEmailSteps(settingSteps.signUp);
                              setShowModal(true);
                              setTimeout(() => {
                                emailFieldRef.current?.focus();
                              }, 5);
                            }}
                          >
                            Change
                          </Link>
                          <a
                            className="field-verify text-xl"
                            aria-label="email verify"
                            edge="end"
                          >
                            <i class="fi fi-ss-check-circle flex check-icon"></i>
                          </a>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {showSecondaryEmailLink && (
                <p className="leading-7 text-right absolute absolute -bottom-[26px] right-0">
                  <span>&nbsp;</span>
                  <Link
                    className="text-primaryLime-500 underline"
                    onClick={() => {
                      Emitter.emit("show_recovery_email_modal", true);
                    }}
                  >
                    +Add Secondary Email Address
                  </Link>
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {(emailSteps === settingSteps.signUp && (
            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full relative"
              onSubmit={handleSubmit(onSubmit)}
            >
              <a
                className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                onClick={() => setShowModal(false)}
              >
                <i className="fi fi-rr-circle-xmark"></i>{" "}
              </a>
              <DialogTitle
                id="alert-dialog-title"
                class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
              >
                Change Email Address
              </DialogTitle>
              <DialogContent className="dialog-content">
                <DialogContentText id="alert-dialog-description">
                  <div>
                    <p className="text-center text-black">
                      Enter your new email address below. <br />
                      An OTP will be sent to this email address to confirm the
                      change.
                    </p>
                    <div className="mt-[50px]">
                      <div className="relative">
                        <label className="mb-2">Email Address</label>
                        <Controller
                          name="emailAddress"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="w-full p-4"
                              inputRef={emailFieldRef}
                              autoFocus={true}
                              type="email"
                              error={!!errors.emailAddress}
                              helperText={errors?.emailAddress?.message}
                              variant="outlined"
                              placeholder="Enter Email address"
                              inputProps={{ maxLength: 40 }}
                              required
                              fullWidth
                            />
                          )}
                        />
                        <div className="absolute tp-message">
                          <AppMessage
                            status={appStatus}
                            message={appMessage}
                            vertical="top"
                            horizontal="center"
                            isOpen={appOpen}
                            timeOut={100}
                          ></AppMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-actions">
                <div className="w-full">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                    aria-label="Sign in"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    type="submit"
                    size="large"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {(loading && (
                      <PulseLoader
                        color="#000000"
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )) ||
                      "Save"}
                  </Button>
                </div>
              </DialogActions>
            </form>
          )) ||
            (emailSteps === settingSteps.signUpWithOTP && (
              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full relative"
                onSubmit={handleSubmit(onOtpSubmit)}
              >
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                >
                  Confirm OTP
                </DialogTitle>
                <DialogContent className="dialog-content">
                  <DialogContentText id="alert-dialog-description">
                    <p className="text-center text-black">
                      Enter the OTP sent on{" "}
                      <span className="font-semibold">{email}</span>
                    </p>
                    <div className="mt-[50px]">
                      <div>
                        <div className="relative">
                          <label>Enter OTP</label>
                          <div className="otp-input mt-2">
                            <OtpInput
                              className="w-full p-4"
                              inputStyle="inputStyle"
                              inputType="number"
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              placeholder="------"
                              shouldAutoFocus={true}
                              required
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                          <div className="absolute tp-message">
                            <AppMessage
                              status={appStatus}
                              message={appMessage}
                              vertical="top"
                              horizontal="center"
                              isOpen={appOpen}
                              timeOut={100}
                            ></AppMessage>
                          </div>
                        </div>
                        <div class="mt-4 text-sm text-black">
                          <p className="text-right">
                            Didn't get OTP?{" "}
                            <a
                              disabled={loading || resendOTPSeconds > 0}
                              href="javascript:void(0);"
                              onClick={() => onReSendOTP()}
                              className="text-primaryLime-500"
                            >
                              Resend OTP{" "}
                            </a>
                            {resendOTPSeconds > 0 && (
                              <span className="text-primaryLime-500">
                                (00:{resendOTPSeconds}
                                {constants.ResendOTPPreference})
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="px-4 pb-4 w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      disabled={(otp.length !== 6 && true) || false}
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Save"}
                    </Button>
                  </div>
                </DialogActions>
              </form>
            )) ||
            (emailSteps === settingSteps.signUpSuccess && (
              <div>
                {/* <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a> */}
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                >
                  <span>
                    <img
                      className="mx-auto block mb-4 w-[50px]"
                      src={successfullyMark}
                      alt="successfully"
                    />
                  </span>
                  Email Address Set!
                </DialogTitle>

                <DialogContent className="dialog-content h-full overflow-visible">
                  <DialogContentText id="alert-dialog-description">
                    <div className="text-center text-black h-full">
                      Your email address has been updated to{" "}
                      <span className="font-semibold">{email}</span>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => setShowModal(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))}
        </Dialog>
      </div>
    </div>
  );
}
export default SettingEmail;
