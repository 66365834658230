import { useEffect, useState, useRef } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import constants from "../../../helpers/constants";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import _ from "../../../../../src/@lodash";
import AuthService from "../../../auth/services/authService";
import UserService from "../../services/userService";
import SettingEmail from "./setting-email";
import SettingPhone from "./setting-phone";
import SettingPassword from "./setting-password";
import RecoveryEmail from "./recovery-email";
import RecoveryPhone from "./recovery-phone";
import Emitter from "../../services/eventEmitter";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import AdminService from "../../services/adminService";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

var schema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z]*$/g, "Please enter between a - z, A - Z")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z]*$/g, "Please enter between a - z, A - Z")
    .required("Last name is required"),
});

var defaultValues = {
  firstName: "",
  lastName: "",
};

function Setting() {
  const roles = constants?.roles();
  const userDetail = AuthService.getUser();
  const settingTabSteps = constants?.settingTabSteps();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(settingTabSteps.profile);
  const [loading, setLoading] = useState(false);
  const [signInpreferenceTypeId, setSignInpreferenceTypeId] = useState(
    userDetail?.users?.signInpreferenceTypeId
  );
  const [showSignInpreference, setShowSignInpreference] = useState(
    userDetail?.users?.password ? true : false
  );
  const [isEditName, setEditName] = useState(
    userDetail?.users?.firstName ? true : false
  );
  const [showModal, setShowModal] = useState(false);
  const [isRecoveryField, setRecoveryField] = useState(
    userDetail?.recoveryPhoneNumbers?.phoneNumber1 ||
      !userDetail?.recoveryEmailAddresses?.emailAddress1 ||
      !userDetail?.recoveryPhoneNumbers?.phoneNumber1 ||
      userDetail?.recoveryEmailAddresses?.emailAddress1
      ? true
      : false
  );

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const firstNameFieldRef = useRef(null);

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;
  const { isValid, dirtyFields, errors } = formState;

  const getProfile = () => {
    setValue("firstName", userDetail?.users?.firstName || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("lastName", userDetail?.users?.lastName || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
  };

  useEffect(() => {
    getProfile();
  }, [setValue]);

  function onSubmit(profileVal) {
    setLoading(true);
    AdminService.updateUser(profileVal)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            AuthService.setUser(response.data);
            userDetail.users.firstName = response.data.users?.firstName;
            userDetail.users.lastName = response.data.users?.lastName;
            AuthService.setUser(userDetail);
            Emitter.emit("user_updated", true);
            setEditName(true);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onCancelClick() {
    getProfile();
    setEditName(userDetail?.users?.firstName ? true : false);
  }

  function handleOnGetUser() {
    var userData = AuthService.getUser();
    setRecoveryField(
      userData?.recoveryPhoneNumbers?.phoneNumber1 ||
        !userData?.recoveryEmailAddresses?.emailAddress1 ||
        !userData?.recoveryPhoneNumbers?.phoneNumber1 ||
        userData?.recoveryEmailAddresses?.emailAddress1
    );
  }

  function handleOnSetPassword() {
    var userData = AuthService.getUser();
    setShowSignInpreference(userData.users?.password ? true : false);
  }

  function handleOnUpdateSignInPreference(signInpreferenceTypeId) {
    AdminService.updateSignInPreference(
      signInpreferenceTypeId,
      userDetail?.users?.userId
    )
      .then((response) => {
        if (response?.statusCode === 200) {
          if (response.data) {
            setSignInpreferenceTypeId(signInpreferenceTypeId);
            userDetail.users.signInpreferenceTypeId = signInpreferenceTypeId;
            AuthService.setUser(userDetail);
          }
        }
      })
      .catch((_errors) => {
        showAppMessage(_errors);
      });
  }

  function onDeleteAccount() {
    setLoading(true);
    AdminService.deleteAccount()
      .then((response) => {
        setLoading(false);
        setShowModal(false);
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            localStorage.clear();
            setTimeout(() => {
              navigate("/", { replace: true });
            }, 10);
          }
        }
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="h-[calc(100vh_-_135px)] overflow-hidden">
      <div className="tp-layout-page setting h-full p-6 md:p-8 lg:p-12 xl:p-[50px] flex flex-col">
        <TabContext value={tabValue}>
          <TabList
            className="bg-gray-50 setting-page-tabs"
            onChange={(e, newValue) => {
              setTabValue(newValue);
            }}
            aria-label="lab API tabs example"
          >
            <Tab label="Profile" value={settingTabSteps.profile} />
            <Tab
              label="Account Setting"
              value={settingTabSteps.accountRecovery}
            />
          </TabList>
          <TabPanel value={settingTabSteps.profile} className="p-0">
            <div className="py-6">
              <h2 className="font-medium text-primaryLime-500">
                Profile Information
              </h2>
              <div>
                Keep your profile up-to-date to enusure we have the correct
                details to contact you.
              </div>
              <div className="mt-[30px]">
                <form
                  name="loginForm"
                  noValidate
                  className="flex flex-col justify-center w-full"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="lg:flex item-center gap-6 w-full">
                    <div className="w-full lg:w-2/4 xl:w-2/6 relative">
                      <label>First Name</label>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="w-full p-4"
                            inputRef={firstNameFieldRef}
                            inputProps={{ maxLength: 20 }}
                            autoFocus={true}
                            disabled={isEditName}
                            type="text"
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message}
                            variant="outlined"
                            placeholder="Enter First Name"
                            required
                            fullWidth
                          />
                        )}
                      />
                      <div className="absolute tp-message">
                        <AppMessage
                          status={appStatus}
                          message={appMessage}
                          vertical="top"
                          horizontal="center"
                          isOpen={appOpen}
                          timeOut={100}
                        ></AppMessage>
                      </div>
                    </div>
                    <div className="w-full lg:w-2/4 xl:w-2/6 mt-[30px] lg:mt-0">
                      <label>Last Name</label>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="w-full p-4"
                            inputProps={{ maxLength: 20 }}
                            disabled={isEditName}
                            type="text"
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message}
                            variant="outlined"
                            placeholder="Enter Last Name"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </div>
                  {!isEditName && (
                    <div className="flex item-center gap-6 w-full mt-[30px]">
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="primary-btn btn-small bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out py-4"
                          aria-label="Sign in"
                          disabled={
                            _.isEmpty(dirtyFields) || !isValid || loading
                          }
                          type="submit"
                          size="large"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {(loading && (
                            <PulseLoader
                              color="#000000"
                              loading={loading}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          )) ||
                            "Save"}
                        </Button>
                      </div>
                      <div className="flex items-center">
                        <Link
                          variant="contained"
                          className="underline"
                          aria-label="Cancel"
                          size="large"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => {
                            onCancelClick();
                          }}
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  )}
                </form>
                {isEditName && (
                  <div className="flex item-center gap-6 w-full mt-[30px]">
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="primary-btn btn-small bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out py-4"
                        aria-label="Sign in"
                        type="submit"
                        size="large"
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => {
                          setEditName(false);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value={settingTabSteps.accountRecovery}
            className="h-full overflow-auto relative"
          >
            <div className="pt-6 h-full">
              <h2 className="font-medium text-primaryLime-500">
                Account Setting
              </h2>
              <div>
                Please choose how you would like to set up your account
                recovery. You can use an email address or a phone number.
              </div>
              <div>
                <div className="lg:flex items-center w-full gap-6 mt-[30px]">
                  <div className="w-full lg:w-2/4 xl:w-2/6">
                    <SettingEmail />
                  </div>
                  <div className="w-full lg:w-2/4 xl:w-2/6 mt-[30px] lg:mt-0">
                    <SettingPhone />
                  </div>
                </div>
                {isRecoveryField ? (
                  <div className="lg:flex items-center w-full gap-6 mt-[30px]">
                    <div className="w-full lg:w-2/4 xl:w-2/6">
                      <RecoveryEmail handleOnGetUser={handleOnGetUser} />
                    </div>
                    <div className="w-full lg:w-2/4 xl:w-2/6 mt-[30px] lg:mt-0">
                      <RecoveryPhone handleOnGetUser={handleOnGetUser} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <RecoveryEmail handleOnGetUser={handleOnGetUser} />{" "}
                    <RecoveryPhone handleOnGetUser={handleOnGetUser} />
                  </div>
                )}
                <div className="lg:flex items-center w-full gap-6 mt-[30px]">
                  <div className="w-full lg:w-2/4 xl:w-2/6">
                    <SettingPassword
                      handleOnSetPassword={handleOnSetPassword}
                    />
                  </div>
                  {showSignInpreference ? (
                    <div className="w-full lg:w-2/4 xl:w-2/6 mt-[30px] lg:mt-0">
                      <div className="flex items-center">
                        <div className="mb-7">
                          <FormControl>
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              className="radio-btn-label"
                            >
                              Sign In Preference
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              class="ml-[10px]"
                              name="row-radio-buttons-group"
                              value={signInpreferenceTypeId}
                              onChange={(ev) => {
                                handleOnUpdateSignInPreference(ev.target.value);
                              }}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="OTP"
                                className="preference-radio-btn border px-4 py-[15px] rounded-12 min-w-32"
                              ></FormControlLabel>
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Password"
                                className="preference-radio-btn border px-4 py-[15px] rounded-12 min-w-32 ps-radio-btn"
                              ></FormControlLabel>
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {userDetail?.users?.roleId !== roles.superAdmin && (
                <div className="flex fixed bottom-[30px] bg-white w-full pt-[30px]">
                  <Link
                    className="w-fit text-red-500 hover:bg-red-100 border border-red-500 no-underline text-base font-medium px-4 md:px-6 py-2.5 flex items-center transition duration-300 ease-in-out rounded-12"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <i class="fi fi-rr-trash mr-2 top-[2px] relative"></i>Delete
                    My Account
                  </Link>
                </div>
              )}
            </div>
          </TabPanel>
        </TabContext>
        <div>
          <Dialog
            open={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle
              id="alert-dialog-title"
              class="font-familjen px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 text-[32px]"
            ></DialogTitle> */}
            <DialogContent className="dialog-content small">
              <DialogContentText id="alert-dialog-description">
                <div className="text-center">
                  <div className="flex items-center justify-center bg-red-100 w-14 h-14 rounded-12 mx-auto mt-6 mb-3">
                    <i class="fi fi-rr-triangle-warning text-red-500 text-2xl flex items-center"></i>
                  </div>
                  <div>
                    <div className="text-[32px] font-semibold text-black">
                      Delete your account
                    </div>
                    <div className="text-black text-muted">
                      {" "}
                      Are you sure you want to delete your account?
                      <span className="block">
                        This action cannot be undone, you will loose access to
                        all your data.
                      </span>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions small">
              <div className="w-full flex items-center justify-center">
                <Button
                  variant="contained"
                  color="secondary"
                  className="border-0 bg-red-500 text-white rounded-xl transition duration-300 ease-in-out shadow-none delete-btn btn-small"
                  aria-label="Sign in"
                  type="submit"
                  size="large"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    onDeleteAccount();
                  }}
                >
                  {(loading && (
                    <PulseLoader
                      color="#000000"
                      loading={loading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )) ||
                    "Delete"}
                </Button>
                <Link
                  variant="contained"
                  color="secondary"
                  className="underline ml-6"
                  aria-label="Sign in"
                  type="submit"
                  size="large"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Link>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default Setting;
