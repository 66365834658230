import { useEffect, useState, useRef } from "react";
import constants from "../../../helpers/constants";
import successfullyMark from "../../../assets/images/success-mark.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import _ from "../../../../../src/@lodash";
import AdminService from "../../services/adminService";
import UserService from "../../services/userService";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import PasswordChecklist from "react-password-checklist";
import Emitter from "../../services/eventEmitter";
import * as yup from "yup";

var passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

var defaultPasswordValues = {
  password: "",
};

var newSchema = yup.object().shape({
  newPassword: yup.string().required("Please enter your password."),
  passwordConfirm: yup.string().required("Please enter your password."),
});

var defaultNewValues = {
  newPassword: "",
  passwordConfirm: "",
};

var resetSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

var defaultResetValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function SettingPassword({ handleOnGetUser, userData}) {  
  const [userDetail, setUserDetail] = useState(userData);
  const settingSteps = constants?.signUpSteps();
  const [passwordSteps, setPasswordSteps] = useState(settingSteps.signUp);
  const [loading, setLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPasswordField, setNewPasswordField] = useState("");
  const [confirmPasswordField, setConfirmPasswordField] = useState("");
  const passwordFieldRef = useRef(null);
  var defaultValues = null;
  var schema = null;

  if (isResetPassword) {
    defaultValues = showModal ? defaultResetValues : defaultPasswordValues;
    schema = showModal ? resetSchema : passwordSchema;
  } else {
    defaultValues = showModal ? defaultNewValues : defaultPasswordValues;
    schema = showModal ? newSchema : passwordSchema;
  }

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: isResetPassword ? yupResolver(schema) : null,
  });

  const getProfile = () => {
    setValue("password", userDetail?.users?.password || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    if (isResetPassword && showModal) {
      setNewPasswordField("");
      setValue("currentPassword", "", {
        shouldDirty: false,
        shouldValidate: false,
      });
      setValue("newPassword", "", {
        shouldDirty: false,
        shouldValidate: false,
      });
      setValue("confirmPassword", "", {
        shouldDirty: false,
        shouldValidate: false,
      });
    } else if (!isResetPassword && showModal) {
      setNewPasswordField("");
      setValue("newPassword", "", {
        shouldDirty: false,
        shouldValidate: false,
      });
      setValue("passwordConfirm", "", {
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  };

  const setResetProfile = () => {
    setNewPasswordField("");
    setConfirmPasswordField("");
    setValue("currentPassword", "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("newPassword", "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("confirmPassword", "", {
      shouldDirty: false,
      shouldValidate: false,
    });
  };

  const setNewProfile = () => {
    setNewPasswordField("");
    setConfirmPasswordField("");
  };

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    Emitter.on("user_detail", (value) => {
      if (value) {
        setUserDetail(value)
        setValue("password", value?.users?.password || "", {
          shouldDirty: false,
          shouldValidate: false,
        });
      }
    });
    return () => {
     // Emitter.off("user_detail");
    };
  }, [setValue]);

  function onSubmit() {
    setLoading(true);
    UserService.updatePassword(newPasswordField, userDetail?.users?.userId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            hideEyeIcon();
            getProfile();
            setPasswordSteps(settingSteps.signUpSuccess);
            handleOnGetUser();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onResetSubmit({ currentPassword }) {
    setLoading(true);
    UserService.resetPassword(currentPassword, newPasswordField, userDetail?.users?.userId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            hideEyeIcon();
            handleOnGetUser();
            setPasswordSteps(settingSteps.signUpSuccess);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function hideEyeIcon() {
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div>
      <div>
        {userDetail?.users?.password ? (
          <form
            name="settingForm"
            noValidate
            className="flex flex-col justify-center w-full"
          >
            <div>
              <label className="mb-2">Password</label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    type={showPassword ? "text" : "password"}
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    placeholder="Enter Password"
                    required
                    fullWidth
                    disabled
                    inputProps={{ maxLength: 14 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <a
                            className="ps-eye-icon"
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            edge="end"
                          >
                            {showPassword ? (
                              <i class="fi fi-rr-eye-crossed"></i>
                            ) : (
                              <i class="fi fi-rr-eye"></i>
                            )}
                          </a>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <p className="leading-7 text-right underline">
                <Link
                  className="text-primaryLime-500"
                  onClick={() => {
                    setPasswordSteps(settingSteps.signUp);
                    setIsResetPassword(true);
                    setShowModal(true);
                    setResetProfile();
                    setTimeout(() => {
                      passwordFieldRef.current?.focus();
                    }, 5);
                  }}
                >
                  Reset Password
                </Link>
              </p>
            </div>
          </form>
        ) : (
          <p className="leading-7">
            <div>Password</div>
            <Link
              className="text-primaryLime-500 underline"
              onClick={() => {
                setPasswordSteps(settingSteps.signUp);
                setShowModal(true);
                setNewProfile();
                setTimeout(() => {
                  passwordFieldRef.current?.focus();
                }, 5);
              }}
            >
              Set Password
            </Link>
          </p>
        )}
      </div>{" "}
      <div>
        <Dialog
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {(passwordSteps === settingSteps.signUp &&
            (isResetPassword ? (
              <form
                name="resetForm"
                noValidate
                className="flex flex-col justify-center w-full relative"
                onSubmit={handleSubmit(onResetSubmit)}
              >
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => {
                    hideEyeIcon();
                    setShowModal(false);
                  }}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                >
                  Reset Your Password
                </DialogTitle>
                <DialogContent className="dialog-content">
                  <DialogContentText id="alert-dialog-description">
                    <div>
                      <p className="text-center text-black">
                        Set Your Password for{" "}
                        <span className="font-semibold">
                          {userDetail?.emailAddresses?.emailAddress1}
                        </span>
                      </p>
                      <div className="mt-[50px]">
                        <div>
                          <label className="mb-2">Current Password</label>
                          <Controller
                            name="currentPassword"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                inputRef={passwordFieldRef}
                                autoFocus={true}
                                type={showCurrentPassword ? "text" : "password"}
                                error={!!errors.currentPassword}
                                helperText={errors?.currentPassword?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                required
                                fullWidth
                                inputProps={{ maxLength: 14 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <a
                                        className="ps-eye-icon"
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          setShowCurrentPassword(
                                            !showCurrentPassword
                                          );
                                        }}
                                        edge="end"
                                      >
                                        {showCurrentPassword ? (
                                          <i class="fi fi-rr-eye-crossed"></i>
                                        ) : (
                                          <i class="fi fi-rr-eye"></i>
                                        )}
                                      </a>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="my-[30px]">
                          <label className="mb-2">New Password</label>
                          <Controller
                            name="newPassword"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                type={showNewPassword ? "text" : "password"}
                                error={!!errors.newPassword}
                                helperText={errors?.newPassword?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                value={newPasswordField}
                                onChange={(val) => {
                                  setNewPasswordField(val.target.value);
                                }}
                                required
                                fullWidth
                                inputProps={{ maxLength: 14 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <a
                                        className="ps-eye-icon"
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          setShowNewPassword(!showNewPassword);
                                        }}
                                        edge="end"
                                      >
                                        {showNewPassword ? (
                                          <i class="fi fi-rr-eye-crossed"></i>
                                        ) : (
                                          <i class="fi fi-rr-eye"></i>
                                        )}
                                      </a>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {newPasswordField.length > 0 ? (
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital"
                              ]}
                              minLength={8}
                              value={newPasswordField}
                              valueAgain={confirmPasswordField}
                              className="ps-validation-msg mt-1"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="relative">
                          <label className="mb-2">Confirm Password</label>
                          <Controller
                            name="confirmPassword"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                type={showConfirmPassword ? "text" : "password"}
                                error={!!errors.confirmPassword}
                                helperText={errors?.confirmPassword?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                required
                                fullWidth
                                value={confirmPasswordField}
                                onChange={(val) => {
                                  setConfirmPasswordField(val.target.value);
                                }}
                                inputProps={{ maxLength: 14 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <a
                                        className="ps-eye-icon"
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          setShowConfirmPassword(
                                            !showConfirmPassword
                                          );
                                        }}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <i class="fi fi-rr-eye-crossed"></i>
                                        ) : (
                                          <i class="fi fi-rr-eye"></i>
                                        )}
                                      </a>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          <div className="absolute tp-message">
                            <AppMessage
                              status={appStatus}
                              message={appMessage}
                              vertical="top"
                              horizontal="center"
                              isOpen={appOpen}
                              timeOut={100}
                            ></AppMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      disabled={ _.isEmpty(dirtyFields) || !isValid || loading ||
                        newPasswordField.length < 1 ||
                        newPasswordField !== confirmPasswordField
                      }
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Save"}
                    </Button>
                  </div>
                </DialogActions>
              </form>
            ) : (
              <form
                name="newForm"
                noValidate
                className="flex flex-col justify-center w-full relative"
                onSubmit={handleSubmit(onSubmit)}
              >
                <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => {
                    hideEyeIcon();
                    handleOnGetUser();
                    setShowModal(false);
                  }}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a>
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                >
                  Set Your Password
                </DialogTitle>
                <DialogContent className="dialog-content">
                  <DialogContentText id="alert-dialog-description">
                    <div>
                      <div>
                        <div className="mt-[50px]">
                          <label className="mb-2">New Password</label>
                          <Controller
                            name="newPassword"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                inputRef={passwordFieldRef}
                                autoFocus={true}
                                value={newPasswordField}
                                type={showNewPassword ? "text" : "password"}
                                error={!!errors.newPassword}
                                helperText={errors?.newPassword?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                onChange={(val) => {
                                  setNewPasswordField(val.target.value);
                                }}
                                required
                                fullWidth
                                inputProps={{ maxLength: 14 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <a
                                        className="ps-eye-icon"
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          setShowNewPassword(!showNewPassword);
                                        }}
                                        edge="end"
                                      >
                                        {showNewPassword ? (
                                          <i class="fi fi-rr-eye-crossed"></i>
                                        ) : (
                                          <i class="fi fi-rr-eye"></i>
                                        )}
                                      </a>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {newPasswordField.length > 0 ? (
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital"
                              ]}
                              minLength={8}
                              value={newPasswordField}
                              valueAgain={confirmPasswordField}
                              className="ps-validation-msg mt-1"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mt-[30px] relative">
                          <label className="mb-2">Confirm Password</label>
                          <Controller
                            name="passwordConfirm"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                type={showConfirmPassword ? "text" : "password"}
                                error={!!errors.passwordConfirm}
                                helperText={errors?.passwordConfirm?.message}
                                variant="outlined"
                                placeholder="Enter Password"
                                value={confirmPasswordField}
                                onChange={(val) => {
                                  setConfirmPasswordField(val.target.value);
                                }}
                                required
                                fullWidth
                                inputProps={{ maxLength: 14 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <a
                                        className="ps-eye-icon"
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          setShowConfirmPassword(
                                            !showConfirmPassword
                                          );
                                        }}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <i class="fi fi-rr-eye-crossed"></i>
                                        ) : (
                                          <i class="fi fi-rr-eye"></i>
                                        )}
                                      </a>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          <div className="absolute tp-message">
                            <AppMessage
                              status={appStatus}
                              message={appMessage}
                              vertical="top"
                              horizontal="center"
                              isOpen={appOpen}
                              timeOut={100}
                            ></AppMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      disabled={
                        newPasswordField.length < 1 ||
                        newPasswordField !== confirmPasswordField
                      }
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Save"}
                    </Button>
                  </div>
                </DialogActions>
              </form>
            ))) ||
            (passwordSteps === settingSteps.signUpSuccess && (
              <div>
                {/* <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => {
                    hideEyeIcon();
                    handleOnSetPassword();
                    setShowModal(false);
                  }}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a> */}
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5"
                >
                  <span>
                    <img
                      className="mx-auto block mb-4 w-[50px]"
                      src={successfullyMark}
                      alt="successfully"
                    />
                  </span>
                  Password Added Successfully
                </DialogTitle>
                <DialogContent className="dialog-content">
                  <DialogContentText id="alert-dialog-description">
                    <p className="text-center text-black">
                      Your password has been updated. You can now use your new
                      password to sign in.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))}
        </Dialog>
      </div>
    </div>
  );
}
export default SettingPassword;
