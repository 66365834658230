import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import authImage from "../../../assets/images/tp-auth.png";
import logo from "../../../assets/images/efile-logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import PasswordChecklist from "react-password-checklist";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import AdminAuthService from "../../services/adminAuthService";

function Invitation() {
  const [invitation, setInvitation] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const [newPasswordField, setNewPasswordField] = useState("");
  const [confirmPasswordField, setConfirmPasswordField] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOtpMessage, setShowOtpMessage] = useState(false);

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    resolver: null,
  });
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (params?.id) {
      AdminAuthService.getInvitation(params.id)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200 && response?.data) {
            setInvitation(response.data);
          } else {
            navigate("/404");
          }
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    } else {
      navigate("/404");
    }
  }, []);
  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  function onMemberSignUp() {
    setLoading(true);
    AdminAuthService.memberSignUp(invitation.emailAddress, invitation.roleId, newPasswordField)
      .then((response) => {
        setLoading(false);
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            navigate("/home");
          }
        }
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  return (
    <div className="bg-black">
      <div className="h-screen overflow-auto flex">
        <div className="container mx-auto">
          <div className="w-full z-10 px-4 md:px-10 xl:px-0 inline-grid xl:flex items-center justify-center h-full">
            <div className="w-full flex flex-col xl:flex-row relative items-center justify-center">
              <div className="w-full xl:w-2/4 text-center mt-10 md:mt-8 xl:mt-0 h-full">
                <img
                  className="mx-auto block xl:hidden mb-4 w-32"
                  src={logo}
                  alt="auth image"
                />
                <img
                  className="mx-auto w-72 sm:w-auto"
                  src={authImage}
                  alt="auth image"
                />
                <h1 className="auth-heading font-semibold mt-2 sm:mt-4 text-white">
                  {" "}
                  <span className="font-normal text-xl">
                    Get Your Taxes Done
                  </span>
                  <br /> With or By A Taxpert<sup>&reg;</sup>
                </h1>
                <p className="text-white">
                  Plan, Prepare, File Your Taxes. Do-It-Yourself or In a Tax
                  Team.
                </p>
              </div>
              <div className="w-full xl:w-2/4 flex justify-center mt-8 xl:mt-0 mb-10 md:mb-8 xl:mb-0">
                <div className="max-w-[600px] w-full relative">
                  <img
                    className="mx-auto hidden xl:block w-32"
                    src={logo}
                    alt="auth image"
                  />
                  <div className="bg-white w-full rounded-xl mt-0 md:mt-12 relative auth-box p-6 sm:p-8 md:p-12 lg:p-[50px]">
                    <div>
                      <h1 className="text-center">Set Your Password</h1>
                      <p className="leading-7 text-center mt-[22px]">
                      Set your password for{" "}
                        <span className="font-semibold">
                          {invitation?.emailAddress}
                        </span>
                      </p>
                      <div className="mt-[50px]">
                        <form
                          name="loginForm"
                          noValidate
                          className="flex flex-col justify-center w-full"
                          onSubmit={handleSubmit(onMemberSignUp)}
                        >
                          <div className="mb-8">
                            <label className="mb-2">Enter Password</label>
                            <Controller
                              name="password"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  type={showPassword ? "text" : "password"}
                                  error={!!errors.password}
                                  helperText={errors?.password?.message}
                                  variant="outlined"
                                  placeholder="Enter Password"
                                  autoFocus={true}
                                  required
                                  fullWidth
                                  value={newPasswordField}
                                  onChange={(val) => {
                                    setNewPasswordField(val.target.value);
                                  }}
                                  inputProps={{ maxLength: 14 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <a
                                          className="ps-eye-icon"
                                          aria-label="toggle password visibility"
                                          onClick={() => {
                                            setShowPassword(!showPassword);
                                          }}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <i class="fi fi-rr-eye-crossed"></i>
                                          ) : (
                                            <i class="fi fi-rr-eye"></i>
                                          )}
                                        </a>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {newPasswordField.length > 0 ? (
                              <PasswordChecklist
                                rules={[
                                  "minLength",
                                  "specialChar",
                                  "number",
                                  "capital",
                                ]}
                                minLength={8}
                                value={newPasswordField}
                                valueAgain={confirmPasswordField}
                                className="ps-validation-msg mt-1"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="mb-8 md:mb-12 lg:mb-[50px] relative">
                            <label className="mb-2">Confirm Password</label>
                            <Controller
                              name="passwordConfirm"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  error={!!errors.passwordConfirm}
                                  helperText={errors?.passwordConfirm?.message}
                                  variant="outlined"
                                  placeholder="Enter Password"
                                  required
                                  fullWidth
                                  value={confirmPasswordField}
                                  onChange={(val) => {
                                    setConfirmPasswordField(val.target.value);
                                  }}
                                  inputProps={{ maxLength: 14 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <a
                                          className="ps-eye-icon"
                                          aria-label="toggle password visibility"
                                          onClick={() => {
                                            setShowConfirmPassword(
                                              !showConfirmPassword
                                            );
                                          }}
                                          edge="end"
                                        >
                                          {showConfirmPassword ? (
                                            <i class="fi fi-rr-eye-crossed"></i>
                                          ) : (
                                            <i class="fi fi-rr-eye"></i>
                                          )}
                                        </a>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            <div className="absolute tp-message">
                              <AppMessage
                                status={appStatus}
                                message={appMessage}
                                vertical="top"
                                horizontal="center"
                                isOpen={appOpen}
                                timeOut={100}
                              ></AppMessage>
                            </div>
                          </div>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                            aria-label="Sign in"
                            type="submit"
                            size="large"
                            sx={{ textTransform: "capitalize" }}
                            disabled={
                              newPasswordField.length < 1 ||
                              newPasswordField !== confirmPasswordField
                            }
                          >
                            {(loading && (
                              <PulseLoader
                                color="#000000"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            )) ||
                              "Sign up"}
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Invitation;
