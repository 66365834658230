class Constants {
  ResendOTPSeconds = 60;
  ResendOTPCount = 1000;
  ResendOTPPreference = "";

  userView = 1;
  userCreate = 2;
  userEdit = 3;
  userDelete = 4;

  roleView = 5;
  roleCreate = 6;
  roleEdit = 7;
  roleDelete = 8;

  invitationView = 9;
  invitationCreate = 10;
  invitationEdit = 11;
  invitationDelete = 12;

  superAdmin = "SuperAdmin";
  admin = "Admin";
  user = "User";

  signInSteps = () => {
    return {
      signIn: 0,
      signInNext: 1,
      signInWithOTP: 2,
      signInWithPassword: 3,
      signInWithRecovery: 4,
      signInWithRecoveryOTP: 5,
    };
  };

  forgotPasswordSteps = () => {
    return {
      forgotPassword: 0,
      forgotPasswordOTP: 1,
      newPassword: 2,
    };
  };

  signUpSteps = () => {
    return {
      signUp: 0,
      signUpWithOTP: 1,
      signUpSuccess: 2,
    };
  };

  signInSkipAttempts = () => {
    return {
      skipAttemptEnd: 2,
    };
  };

  messageStatusCodes = () => {
    return {
      success: 200,
      badRequest: 400,
      internalServerError: 500,
    };
  };

  settingTabSteps = () => {
    return {
      profile: 0,
      accountRecovery: 1,
    };
  };

  sidebarMenu = () => {
    return {
      dashboard: 0,
      settings: 1,
      users: 2,
      roles: 3,
      adminUsers: 4,
      adminDashboard: 5,
    };
  };

  signInPreferenceTypeIDs = () => {
    return {
      signUp: 0,
      signInWihOtp: 1,
      signInWihPassword: 2,
      signInWihPasswordAndNoPreference: 3,
    };
  };

  roles = () => {
    return {
      superAdmin: 1,
      admin: 2,
      user: 3,
    };
  };

  resendOTP = () => {
    if (this.ResendOTPCount > 0) {
      const timerId = setInterval(() => {
        return (endCount) => endCount - 1;
      }, this.ResendOTPCount);
      return () => clearInterval(timerId);
    }
  };

  userPermissions = () => {
    return [
      {
        name: "User",
        view: this.userView,
        create: this.userCreate,
        edit: this.userEdit,
        delete: this.userDelete,
      },
      {
        name: "Role",
        view: this.roleView,
        create: this.roleCreate,
        edit: this.roleEdit,
        delete: this.roleDelete,
      },
      {
        name: "Invitation",
        view: this.invitationView,
        create: this.invitationCreate,
        edit: this.invitationEdit,
        delete: this.invitationDelete,
      },
    ];
  };
}
const instance = new Constants();
export default instance;
