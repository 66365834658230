import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../auth/services/authService";
import Emitter from "../main/services/eventEmitter";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import constants from "../helpers/constants";

function Header({ handleOnAciveClick }) {
  var userDetail = AuthService.getUser();
  const roles = constants?.roles();
  const navigate = useNavigate();
  const [usernName, setUserName] = useState(
    userDetail?.users?.firstName
      ? userDetail.users?.firstName + " " + userDetail.users?.lastName
      : ""
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      className="profile-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        size="small"
        onClick={() => {
          handleMenuClose();
        }}
      >
        <i class="fi fi-rr-user-headset mr-3 top-[2px] relative"></i>Support
      </MenuItem>
      <MenuItem
        size="small"
        onClick={() => {
          signOut();
          handleMenuClose();
        }}
      >
        <i class="fi fi-rr-sign-out-alt mr-3 top-[2px] relative"></i>Sign Out
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    Emitter.on("user_updated", (value) => {
      if (value) {
        userDetail = AuthService.getUser();
        setUserName(
          userDetail?.users?.firstName + " " + userDetail?.users?.lastName
        );
      }
    });

    return () => {
      Emitter.off("user_updated");
    };
  }, [usernName]);

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function signOut() {
    setTimeout(() => {
      if (userDetail?.users?.roleId === roles.superAdmin || userDetail?.users?.roleId === roles.admin) {
        localStorage.clear();
        navigate("/admin/sign-in", { replace: true });
      } else {
        localStorage.clear();
        navigate("/", { replace: true });
      }
    }, 10);
  }
  return (
    <div className={"w-full"}>
      <div className="h-[60px] lg:h-[80px] border-b flex items-center justify-between px-6 md:px-8 lg:px-12 xl:px-[50px]">
        <div className="flex items-center">
          <a className="block lg:hidden" onClick={() => handleOnAciveClick()}>
            <i className="fi fi-rr-menu-burger text-lg mr-4"></i>
          </a>
          <p className="text-base font-semibold flex items-center">
            {/* <span className="hidden md:block mr-1">Welcome </span> {usernName}{" "} */}
          </p>
        </div>
        <div className="flex items-center">
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                class="flex items-center w-full justify-center rounde hover:bg-gray-50 font-medium"
                id="menu-button"
                aria-expanded="true"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={(e) => {
                  handleProfileMenuOpen(e);
                }}
              >
                <span className="bg-black w-10 h-10 rounded-full flex items-center justify-center">
                  <i class="fi fi-rr-user text-white text-lg mt-1.5"></i>
                </span>
                <span
                  className={
                    usernName
                      ? "hidden md:block capitalize md:pl-2"
                      : "hidden md:block capitalize"
                  }
                >
                  {usernName}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {renderMenu}
    </div>
  );
}
export default Header;
