import http from "../../config/http-common";

class InvitationService {
    getInvitations = (phoneNumber = 1, pageSize = 10, sortColumn ="InvitationSentDateTime", sortOrder ="desc", searchTerm = "") => {
        return new Promise((resolve, reject) => {
          http
            .get("Invitation/GetInvitations/" + phoneNumber + "/" + pageSize + "/" + sortColumn+"/"+ sortOrder+"/" + searchTerm)
            .then((response) => {
              if (response.data) {
                resolve(response.data);
              } else {
                reject(response.data);
              }
            });
        });
      };

      sendMemberInvitation = (
        data
      ) => {
        return new Promise((resolve, reject) => {
          http
            .post(
              "Invitation/SendMemberInvitation", data
            )
            .then((response) => {
              if (response.data) {
                resolve(response.data);
              } else {
                reject(response.data);
              }
            });
        });
      }; 
      
      updateSignInPreference = (signInpreferenceTypeId) => {
        return new Promise((resolve, reject) => {
          http
            .put("AdminUser/UpdateSignInPreference/" + signInpreferenceTypeId)
            .then((response) => {
              if (response.data) {
                resolve(response.data);
              } else {
                reject(response.data);
              }
            });
        });
      };
}

const instance = new InvitationService();

export default instance;