import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import UserService from "../../services/userService";
import AdminService from "../../services/adminService";
import AuthService from "../../../auth/services/authService";
import constants from "../../../helpers/constants";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import PulseLoader from "react-spinners/PulseLoader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import AppMessage from "../../../main/app-message/message";
import _ from "../../../../../src/@lodash";

const defaultValues = {
  searchType: "",
  search: "",
};

function UserList() {
  const userDetail = AuthService.getUser();
  const navigate = useNavigate();
  const roles = constants?.roles();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [searchTypeID, setSearchTypeID] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [permission, setPermission] = useState(
    userDetail.roles.permissionIds
      ? userDetail.roles.permissionIds.split(",").map(Number)
      : []
  );

  var columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      disableColumnMenu: true,
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 220,
      disableColumnMenu: true,
    },
    {
      field: "roleName",
      headerName: "Role",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "userStatus",
      headerName: "Status",
      width: 140,
      disableColumnMenu: true,
    },
    {
      field: "userId",
      headerName: "Action",
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        setSelectedUser(params.row);
        return (
          <>
            {permission.includes(constants.userEdit) ||
            userDetail?.users?.roleId === roles.superAdmin ? (
              <>
                <a
                  class="text-primaryLime-500 underline text-base"
                  target={"_blank"}
                  href={"/admin/admin-detail/" + params.value}
                >
                  Edit
                </a>
              </>
            ) : (
              <></>
            )}
            {(permission.includes(constants.userEdit) &&
              permission.includes(constants.userDelete)) ||
            userDetail?.users?.roleId === roles.superAdmin ? (
              <> | </>
            ) : (
              <></>
            )}

            {permission.includes(constants.userDelete) ||
            userDetail?.users?.roleId === roles.superAdmin ? (
              <>
                <a
                  class="text-primaryLime-500 underline text-base"
                  target={"_blank"}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </a>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const fetchDataWithSortingAndPagination = async (
    sortField,
    sortDirection,
    searchText = ""
  ) => {
    setLoading(true);
    AdminService.getUsers(
      page + 1,
      pageSize,
      sortField,
      sortDirection,
      searchTypeID,
      searchText
    )
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setRows(response.data);
            setRowCount(response.rowCount);
          } else {
            columns = [];
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  };

  useEffect(() => {
    if (
      !permission.includes(constants.userView) &&
      userDetail?.users?.roleId !== roles.superAdmin
    ) {
      navigate("/404");
    } else {
      fetchDataWithSortingAndPagination("CreationDateTime", "desc");
    }
  }, [page, pageSize]);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function onDeleteAccount() {
    if (selectedUser?.roleId === roles.admin) {
      AdminService.deleteAccount(selectedUser?.userId, selectedUser?.accountId)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response.data) {
              setShowDeleteModal(false);
              fetchDataWithSortingAndPagination("CreationDateTime", "desc");
            }
          }
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    } else {
      UserService.deleteAccount(selectedUser?.userId, selectedUser?.accountId)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response.data) {
              setShowDeleteModal(false);
              fetchDataWithSortingAndPagination("CreationDateTime", "desc");
            }
          }
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function onSearchSubmit({ search }) {
    fetchDataWithSortingAndPagination("CreationDateTime", "desc", search);
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="h-3/4">
      {/* className="h-full" */}
      <div className="mt-[30px] mb-[30px]">
        <form
          name="loginForm"
          noValidate
          onSubmit={handleSubmit(onSearchSubmit)}
        >
           <div className="lg:flex item-center gap-6 w-full">
            <div className="w-full lg:w-2/6 xl:w-1/6 relative">
              <label className="block">Search Type</label>
              <Controller
                className="w-full role-menu"
                name="searchType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-full"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchTypeID}
                    onChange={(event) => {
                      setSearchTypeID(event?.target?.value);
                    }}
                  >
                    <MenuItem value="0" selected>
                      Search By
                    </MenuItem>
                    <MenuItem value="1">User Name</MenuItem>
                    <MenuItem value="2">Email Address</MenuItem>
                  </Select>
                )}
              />
            </div>
            {searchTypeID > 0 && (
              <>
                <div className="w-full lg:w-2/6 xl:w-1/6 relative">
                  <label className="block">Search</label>
                  <Controller
                    name="search"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full p-4"
                        type="text"
                        variant="outlined"
                        placeholder="Enter Search Text"
                        required
                        fullWidth
                        autoFocus={true}
                        inputProps={{ maxLength: 40 }}
                      />
                    )}
                  />
                </div>
                <div className="relative">
                  <div className="flex item-center gap-6 w-full mt-[30px]">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="primary-btn btn-small bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out py-4"
                      aria-label="Sign in"
                      disabled={loading}
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {(loading && (
                        <PulseLoader
                          color="#000000"
                          loading={loading}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )) ||
                        "Search"}
                    </Button>
                  </div>
                  <div className="absolute tp-message">
                    <AppMessage
                      status={appStatus}
                      message={appMessage}
                      vertical="top"
                      horizontal="center"
                      isOpen={appOpen}
                      timeOut={100}
                    ></AppMessage>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
      <DataGrid
        rows={rows}
        columns={rows.length > 0 ? columns : []}
        pageSize={pageSize}
        rowCount={rowCount}
        rowsPerPageOptions={[5, 10, 25]}
        page={page}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        columnVisibilityModel={{
          id: false,
          userId:
            permission.includes(constants.userEdit) ||
            permission.includes(constants.userDelete) ||
            userDetail?.users?.roleId === roles.superAdmin,
        }}
        sortingOrder={["asc", "desc"]}
        onSortModelChange={(newSortModel) => {
          const sortField = newSortModel[0]?.field;
          const sortDirection = newSortModel[0]?.sort;
          fetchDataWithSortingAndPagination(sortField, sortDirection);
        }}
        onPaginationModelChange={(mode) => {
          setPage(mode.page);
          setPageSize(mode.pageSize);
          fetchDataWithSortingAndPagination("CreationDateTime", "desc");
        }}
        loading={loading}
        paginationMode="server"
        pagination
      />
      <div>
        <Dialog
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle
              id="alert-dialog-title"
              class="font-familjen px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 text-[32px]"
            ></DialogTitle> */}
          <DialogContent className="dialog-content small">
            <DialogContentText id="alert-dialog-description">
              <div className="text-center">
                <div className="flex items-center justify-center bg-red-100 w-14 h-14 rounded-12 mx-auto mt-6 mb-3">
                  <i class="fi fi-rr-triangle-warning text-red-500 text-2xl flex items-center"></i>
                </div>
                <div>
                  <div className="text-[32px] font-semibold text-black">
                    Delete your account
                  </div>
                  <div className="text-black text-muted">
                    {" "}
                    Are you sure you want to delete your account?
                    <span className="block">
                      This action cannot be undone, you will loose access to all
                      your data.
                    </span>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-actions small">
            <div className="w-full flex items-center justify-center">
              <Button
                variant="contained"
                color="secondary"
                className="border-0 bg-red-500 text-white rounded-xl transition duration-300 ease-in-out shadow-none delete-btn btn-small"
                aria-label="Sign in"
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  onDeleteAccount();
                }}
              >
                {(loading && (
                  <PulseLoader
                    color="#000000"
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )) ||
                  "Delete"}
              </Button>
              <Link
                variant="contained"
                color="secondary"
                className="underline ml-6"
                aria-label="Sign in"
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Cancel
              </Link>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default UserList;
